<template>
	<div class="notice_popup tech">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit">적립포인트 실행</div>
		<div class="member">
			<tec-summary v-model="data" :tecMberSeq="param.saveMberSeq" after="님에게" v-if="param.type == 'MBER'"/>
			<span class="name" v-else-if="param.type == 'CORP'">'{{param.corpNm}}' 님에게</span><br/>
			<span class="name">{{param.pointSaveAmt | number}}</span><img class="point" src="/images/admin/point_s.png" alt="포인트"/>를 
			'<SelectComp type="text" cdId="PRO129" :value="param.pointSaveDivCd"/>'하시겠습니까?
		</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', false)">취소</div>
			<div class="btn" @click="save">예</div>
		</div>
	</div>
</template>
<script>
import tecSummary from "@/components/highpro/TecSummary.vue";

export default {
	components: { tecSummary },
	props: { param:Object },
	data() {
		return {
			input: {
				saveMberSeq		: this.param.saveMberSeq,
				saveCorpSeq		: this.param.saveCorpSeq,
				pointSaveCd		: this.param.pointSaveCd,
				pointSaveAmt	: this.param.pointSaveAmt,
				pointSaveDtlCont: this.param.pointSaveDtlCont,
				preTranHistorySeq: this.param.preTranHistorySeq,
			},
			data: {},
		};
	},
	mounted(){
	},
	methods: {
		save(){
			this.$.httpPost('/api/mem/pit/savePoint', this.input)
				.then(() => { this.$emit('close', true); }).catch(this.$.httpErrorCommon);
		}
	},
};
</script>
